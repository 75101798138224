import React, {Component, useEffect, useState } from 'react';
import {observer} from 'mobx-react';
import { reaction } from 'mobx';
import { Flex, VFlex } from '../Flex';


interface DialogProps<T> {
  storeGetter: () => T
}

const Dialog = observer((props:DialogProps) => {
  const [s, update] = useState();
  useEffect(() => {
    if (props.storeGetter) {
      return reaction(props.storeGetter, () => update(Date.now()));
    }
    return null;
  }, [props.storeGetter]);
  const isShow = props.storeGetter();
  return (
    <React.Fragment>
      <VFlex
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          backgroundColor: '#000000A0',
          zIndex: 999,
          top: 0,
          justifyContent: 'center',
          opacity: isShow ? 1 : 0,
          pointerEvents: isShow ? 'all' : 'none',
          transition: 'all 0.3s',
          alignItems: 'center',
        }}
      >
        {props.children}
      </VFlex>
    </React.Fragment>
  );
});

export const DialogHeader = ({onCloseClick, title}) => {
  return (
    <Flex
      style={{justifyContent: 'space-between', alignItems: 'center'}}
    >
      <h5>{title}</h5>
      <h5
        style={{
        }}
        className="simple-btn"
        onClick={onCloseClick}
      >
        X
      </h5>
    </Flex>
  );
};

export default Dialog;
