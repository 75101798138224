import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { VFlex } from './Flex';
import { store } from '../mobx/store';

interface OverlayMenuProps {
}

const OverlayMenu = observer((props:OverlayMenuProps) => {
  return (
    <VFlex
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // background: 'linear-gradient(180deg, #1a1e1fFF 0%, #1a1e1f00 100%)',
        backgroundColor: '#000000E0',
        paddingTop: 90,
        pointerEvents: store.isShowOverlayMenu ? 'all' : 'none',
        opacity: store.isShowOverlayMenu ? 1 : 0,
        transform: `translateX(${store.isShowOverlayMenu ? '0px' : '-100vw'})`,
        transition: 'all 0.3s',
        zIndex: 120,
      }}
      onClick={() => {
        store.isShowOverlayMenu = false;
      }}
    >
      <div className="menu-flex-wrapper">
        <div className="mobile-nav-links-contain">
          <a href="#Home" data-w-id="6570dc26-103f-0bb1-d183-fa6882f52179" className="nav_link mobile-link">Home</a>
          <a href="#Auction" data-w-id="6570dc26-103f-0bb1-d183-fa6882f5217b" className="nav_link mobile-link">Auction</a>
          <a href="#FAQs" data-w-id="6570dc26-103f-0bb1-d183-fa6882f5217d" className="nav_link mobile-link">FAQ</a>
          <a href="#Team" data-w-id="6570dc26-103f-0bb1-d183-fa6882f5217f" className="nav_link mobile-link">Team</a>
          <a href="#Partners" data-w-id="6570dc26-103f-0bb1-d183-fa6882f52181" className="nav_link mobile-link">Partners</a>
          <div data-w-id="6570dc26-103f-0bb1-d183-fa6882f52183" className="social_links_wrapper navbar_sm">
            <a target="_blank" href="/#" className="social_link w-inline-block"><img width="13" sizes="100vw" src="images/faceb.png" srcSet="images/faceb-p-500.png 500w, images/faceb.png 766w" alt="" /></a>
            <a target="_blank" href="/#" className="social_link w-inline-block"><img width="13" sizes="100vw" src="images/2000px-Instagram_simple_icon.svg.png" srcSet="images/2000px-Instagram_simple_icon.svg-p-500.png 500w, images/2000px-Instagram_simple_icon.svg-p-800.png 800w, images/2000px-Instagram_simple_icon.svg-p-1080.png 1080w, images/2000px-Instagram_simple_icon.svg-p-1600.png 1600w, images/2000px-Instagram_simple_icon.svg.png 1970w" alt="" /></a>
            <a target="_blank" href="/#" className="social_link w-inline-block"><img width="13" sizes="100vw" src="images/twitter1.png" srcSet="images/twitter1-p-500.png 500w, images/twitter1-p-800.png 800w, images/twitter1.png 827w" alt="" /></a>
          </div>
          <a data-w-id="6570dc26-103f-0bb1-d183-fa6882f5218a" href="/#" className="button navbar-button w-button">⇓   Whitepaper</a>
        </div>
        <div
          style={{zIndex: 100}}
          data-w-id="6570dc26-103f-0bb1-d183-fa6882f5218c"
          className="close-wrapper"
          onClick={(evt) => {
            store.isShowOverlayMenu = false;
            evt.stopPropagation();
          }}
        >
          <div className="close-line dark_close" />
          <div className="close-line line-2 dark_close" />
        </div>
      </div>
    </VFlex>
  );
});

export default OverlayMenu;
