import React, {Component} from 'react';
import {observer} from 'mobx-react';

interface TeamProps {
}

const Team = observer((props:TeamProps) => {
  return (
    <section id="Team" className="team the-team wf-section">
      <div className="div-block-12">
        <h2 className="centered-heading heading-2">MEET THE TEAM</h2>
        <p className="centered-subheading">
          <strong className="team-font">
            Executive Team
            <br />
          </strong>
        </p>
        <div className="team-grid-3">
          <div id="w-node-abaed53f-975a-2b7b-6880-9013ef9a2d45-8903aa5a" className="team-card-2">
            <img src="images/fdgcVmxi_400x400.jpg" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">ALEXETH</div>
            <div className="team-member-position">
              Founder, CEO
              <br />
              Researcher
            </div>
          </div>
          <div id="w-node-e7f9c3b7-92e7-6564-8e10-688364160a00-8903aa5a" className="team-card-2">
            <img src="images/AHhtsxc4_400x400.jpg" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">KOVACS</div>
            <div className="team-member-position">
              <strong>
                General Manager, Partnership
                <br />
              </strong>
            </div>
          </div>
          <div id="w-node-d189dbc0-a52d-83c4-49c5-7c8046f6d8a6-8903aa5a" className="team-card-2">
            <img src="images/IGh7AwHs_400x400.jpg" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">NEAL</div>
            <div className="team-member-position">
              <strong>Research Lead</strong>
              <br />
            </div>
          </div>
          <div id="w-node-_97543da1-7f29-11c3-9c50-13f05ad1b3c3-8903aa5a" className="team-card-2">
            <img src="images/profile_from_vv.jpg" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 45vw, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 11vw" srcSet="images/profile_from_vv.jpg 500w" alt="" className="image-2" />
            <div className="team-member-name">DR. NG</div>
            <div className="team-member-position">
              <strong>Researcher in CG/AI/Data</strong>
              <br />
            </div>
          </div>
          <div id="w-node-_7f7ca47a-56eb-28dd-1da5-9e80a25c0b8e-8903aa5a" className="team-card-2">
            <img src="images/h5Kw020M_400x400.jpg" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">VICKY</div>
            <div className="team-member-position">
              <strong>
                Web2 partnership, outreach
                <br />
              </strong>
            </div>
          </div>
          <div id="w-node-c0cf45d3-20ce-90a7-745e-c06fa872e60d-8903aa5a" className="team-card-2">
            <img src="images/Cmhjg6Mf_400x400.jpg" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">MOWORLD</div>
            <div className="team-member-position">
              <strong>
                Artist, Content
                <br />
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div className="committ">
        <p className="centered-subheading">
          <strong className="team-font">
            Committee
            <br />
          </strong>
        </p>
        <div className="team-grid-committee">
          <div id="w-node-_232789f8-b189-a943-ce85-310dbce42d29-8903aa5a" className="team-card-2">
            <img src="images/9x9x9.jpg" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">9X9X9</div>
            <div className="team-member-position">
              <strong>
                OpenDAO, Hadeswap, NFT collector
                <br />
              </strong>
            </div>
          </div>
          <div id="w-node-_232789f8-b189-a943-ce85-310dbce42d31-8903aa5a" className="team-card-2">
            <img src="images/BvZyo_Gi_400x400.jpg" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">CHRISTIAN</div>
            <div className="team-member-position">
              <strong>
                VC, NFT collector, Investor
                <br />
              </strong>
            </div>
          </div>
          <div id="w-node-_232789f8-b189-a943-ce85-310dbce42d41-8903aa5a" className="team-card-2">
            <img src="images/dyno.png" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 45vw, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 12vw" srcSet="images/dyno-p-500.png 500w, images/dyno.png 631w" alt="" className="image-2" />
            <div className="team-member-name">DYNO</div>
            <div className="team-member-position">
              <strong>
                OpenDAO, Lunar, VC
                <br />
              </strong>
            </div>
          </div>
          <div id="w-node-_0e74df65-9606-7a1b-690f-b302d3c5a3f9-8903aa5a" className="team-card-2">
            <img src="images/w2g4Wq4P_400x400.png" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">KEUNGZ</div>
            <div className="team-member-position">
              <strong>
                KzG, YogaPetz, Collector
                <br />
              </strong>
            </div>
          </div>
          <div id="w-node-d7271476-6646-424a-e5b8-22d6fb084e7f-8903aa5a" className="team-card-2">
            <img src="images/iF5SGFeM_400x400-1.jpg" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">MARK</div>
            <div className="team-member-position">
              <strong>
                SGG, Investor
                <br />
              </strong>
            </div>
          </div>
          <div id="w-node-_232789f8-b189-a943-ce85-310dbce42d39-8903aa5a" className="team-card-2">
            <img src="images/markman.png" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">MARKMAN</div>
            <div className="team-member-position">
              <strong>Investor, Tech, Marketiger</strong>
              <br />
            </div>
          </div>
          <div id="w-node-_26b6dfb0-d09b-e94a-324d-eed3bcade293-8903aa5a" className="team-card-2">
            <img src="images/onz.png" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">ONZDROID</div>
            <div className="team-member-position">
              <strong>Investor, Tech, Researcher</strong>
              <br />
            </div>
          </div>
          <div id="w-node-_567a541f-20c5-3857-c35d-0fa8579ad314-8903aa5a" className="team-card-2">
            <img src="images/guf.png" loading="lazy" alt="" className="image-2" />
            <div className="team-member-name">PROF. GU</div>
            <div className="team-member-position">
              <strong>
                Researcher in AR, VR, IoT
                <br />
              </strong>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Team;
