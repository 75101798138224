import React, {Component} from 'react';
import {observer} from 'mobx-react';

interface PartnersSectionProps {
}

const PartnersSection = observer((props:PartnersSectionProps) => {
  return (
    <section id="Partners" className="logos-title-small wf-section">
      <div className="container-3">
        <div className="heading-2">Our Partners</div>
        <div className="clients-wrapper-three">
          <img src="images/partner_earpitz-1.png" loading="lazy" width="116" sizes="(max-width: 479px) 40vw, 116px" alt="AriseHealth logo" srcSet="images/partner_earpitz-1-p-500.png 500w, images/partner_earpitz-1-p-800.png 800w, images/partner_earpitz-1-p-1080.png 1080w, images/partner_earpitz-1-p-1600.png 1600w, images/partner_earpitz-1-p-2000.png 2000w, images/partner_earpitz-1.png 2362w" className="clients-image-three" />
          <img src="images/BasPQxlz_400x400.jpg" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
          <img src="images/fo42wXI6_400x400.jpg" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
          <img src="images/UpmFmuRD_400x400.jpg" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
          <img src="images/XeqxsH3Z_400x400.jpg" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
          <img src="images/vhqI2qxD_400x400.png" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
        </div>
        <div className="clients-wrapper-three">
          <img src="images/72WafYf__400x400.jpg" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
          <img src="images/sgg.jpg" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
          <img src="images/dnNBcFXM_400x400.jpg" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
          <img src="images/PL2weNGS_400x400.jpg" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
          <img src="images/5spzYD5z_400x400.jpg" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
          <img src="images/23090A74-424B-4DDA-A4A1-76EC66F55088.png" loading="lazy" width="116" alt="AriseHealth logo" className="clients-image-three" />
        </div>
      </div>
    </section>
  );
});

export default PartnersSection;
