import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';

import { Contract } from '@ethersproject/contracts';
import MINT_ABI from '../abi/abi.json';
import { NETWORK_CONFIG } from '../utils/constants';

export function getSigner(library: Web3Provider, account: string) {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider {
  return account ? getSigner(library, account) : library;
}


export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account));
}


export function useActiveWeb3React() {
  const context = useWeb3React();
  const contextNetwork = useWeb3React('NETWORK');
  return context.active ? context : contextNetwork;
}


function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const ctx = useActiveWeb3React();
  return useMemo(() => {
    const library = ctx.library;
    const account = ctx.account;
    if (!address || !ABI || !library) return null;
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, ctx, withSignerIfPossible]);
}
