import React, {Component} from 'react';
import Reveal from 'react-reveal';
import {observer} from 'mobx-react';

interface FAQProps {
}

const FAQ = observer((props:FAQProps) => {
  return (
    <div id="FAQs" className="section wf-section">
      <div className="wrapper">
        <h2 className="centered_text"><strong className="heading-2">faqs</strong></h2>
        <Reveal
          left
        >
          <div data-w-id="9dafa108-cc0b-8321-c95d-4c37a84e49b1" className="faq bottom_faq">
            <div className="faq-answer-wrap">
              <h4 className="heading"><strong>What is Taiji Labs?</strong></h4>
              <p className="paragraph">
                <strong>
                  Taiji Labs is more than just an NFT collection. It is an ecosystem, a brand, an identity; and ultimately an elite investment and venture capitalist-based NFT collection that invests in a wide variety of areas, such as GameFi, NFT collections, DApps, DeFi, AR, VR, data &amp; privacy, and other blockchain-based businesses. Projects are vetted by our world-class researchers and notable web3 advisors. There are zero fees and holders get to participate in the research and investment process from start to finish.
                  <br />
                  ‍
                  <br />
                  Distinctive from a typical community VC, projects will only be presented as an investment after they are rigorously vetted through our Taiji Process and agreed upon by the community. There are zero fees and 100% transparency, so holders directly benefit from taking part in the vetting and eventual voting process, having the ability to engage with the project founders directly in AMAs and participate in beta/alpha testing.
                  <br />
                  <br />
                  The primary benefit of being a Taiji NFT holder are guaranteed allocations starting at $500, and some are FCFS. There are also instant perks to Taji Lab holders. Holders will receive an Earpitz NFT airdrop, automatic whitelist to YogaPetz and Oldeus, and many other top-tier whitelists in the future. The private Discord offers networking, whitelists, AMAs &amp; townhalls, and exclusive and high quality alpha channels.
                  {' '}
                  <br />
                  ‍
                  <br />
                  Taiji Labs was created to bridge all levels of investors to opportunities through our NFT collection. Holders of Taiji Labs have the privilege to participate in high-end investment opportunities. We are first and foremost a community that believes in an even level playing field for investors, where holders have a unique chance to participate in the very early stages of pre-vetted projects
                </strong>
                <br />
              </p>
            </div>
          </div>
        </Reveal>
        <Reveal
          left
        >
          <div data-w-id="306a91c7-df26-dc90-9fd0-6a7ea5fb765c" className="faq bottom_faq">
            <div className="faq-answer-wrap">
              <h4 className="heading">
                <strong>
                  What is Taiji Labs Genesis Key supply?
                  <br />
                </strong>
              </h4>
              <p className="paragraph">
                <strong>
                  1,500 total, all minted to Taiji Labs vault wallet.
                  <br />
                </strong>
                <span className="season-text"><strong> - First Season – </strong></span>
                <strong className="bold-text">288</strong>
                <span className="season-text">
                  <strong>
                    , blind auction (Q4, 2022)
                    <br />
                    {' '}
                    - Second Season – 300, whitelist-based (Q1, 2023)
                    <br />
                    {' '}
                    - Third Season  – 400, whitelist-based (Q2, 2023)
                    <br />
                    {' '}
                    - Fourth Season – 400, TBD (Q4, 2023)
                    <br />
                    {' '}
                    - The Treasury- 112, for Marketing/Promotion/Hiring purposes
                  </strong>
                </span>
                <br />
              </p>
            </div>
          </div>
        </Reveal>
        <Reveal
          left
        >
          <div data-w-id="83806f62-5800-c768-5d04-6ae58df500d2" className="faq bottom_faq">
            <div className="faq-answer-wrap">
              <h4 className="heading">
                <strong>
                  What are the launch details?
                  <br />
                </strong>
              </h4>
              <p className="paragraph">
                <strong>
                  The first season will be a blind auction that will last for 72 hours with a minimum starting bid of 0.15 ETH, which is the deposit. The 0.15 is included in your final bid. After the 72-hour period, the top 288 bidders will win, with the final price being the 288th bid.
                  <br />
                  <br />
                  Phase 1: Auction (72 hours)
                  <br />
                  - Pay the deposit of 0.15 ETH and place your bid.
                  <br />
                  - You only need to make the deposit once, regardless of your bid or later bids.
                  <br />
                  - You can raise your bid but not lower it.
                  <br />
                  - The deposit is included in your final bid.
                  {' '}
                  <br />
                  <br />
                  Phase 2: Payment, refund, processing
                  <br />
                  - After phase 1 ends, the top 288 bids are the winners.
                  <br />
                  - The final price is the 288th bid.
                  <br />
                  - Bidders will have 48 hours to pay the difference between their deposit and the final bid. For example, if the final price is 1 ETH, the bidder will need to pay 0.85 ETH within 48 hours.
                  <br />
                  - If you win but do not pay the difference, you do not get a refund of your deposit.
                  <br />
                  - Anyone below the 288th bid will get a 100% refund.
                  <br />
                  - Taiji Labs Genesis Key will be airdropped to all winners within 48 hours.
                </strong>
                <br />
              </p>
            </div>
          </div>
        </Reveal>
        <Reveal
          left
        >
          <div data-w-id="fbbcd67b-6786-ae10-6f4d-c6c8572ab568" className="faq bottom_faq">
            <div className="faq-answer-wrap">
              <h4 className="heading"><strong>What is the mint price?</strong></h4>
              <p className="paragraph">
                <strong>
                  Season 1 will be a blind auction. The starting bid is 0.15ETH. The 288th bid will be the final price.
                  <br />
                  <br />
                  Season 2 will be priced at 70% of the current floor price. Season 2 is meant to expand and strengthen the community by bringing in new investors to contribute. A community that continues to evolve and build is essential to the long-term success of Taiji Labs. Season 1 holders can be confident that their NFTs will retain their value because of the staking bonuses and other season 1 perks, keeping them ahead of the curve.
                </strong>
                <br />
              </p>
            </div>
          </div>
        </Reveal>
        <Reveal
          left
        >
          <div data-w-id="b4ba24cb-269f-2c40-eb5f-de8b2fe431d4" className="faq bottom_faq">
            <div className="faq-answer-wrap">
              <h4 className="heading"><strong>What are the benefits?</strong></h4>
              <p className="paragr1aph">
                <strong>
                  There are guaranteed allocations starting at just $500, and some are FCFS. Some instant perks:
                  <br />
                  - Unlock your first investment opportunity
                  <br />
                  - Earpitz NFT airdrop
                  <br />
                  - WL: Earpitz, YogaPetz, Oldeus, KubZ
                  <br />
                  - High-quality future whitelists
                  <br />
                  - Weekly townhall and AMA
                  <br />
                  - Networking
                  <br />
                  - Access to the Taiji Labs Educational Institute.
                  <br />
                  - Exclusive and high quality alpha channels by professional alpha callers
                  <br />
                  - Staking bonuses. The more you stake, the more allocations you get
                  <br />
                  - Ability to talk directly to founders of projects and be a part of the vetting process and nominate projects.
                  <br />
                  - In real life benefits connected to web2 companies, such as IRL experiences and merchandise.
                  <br />
                  <br />
                  The only way to participate in direct investments deals is to have your Taiji Labs NFT staked. You can stake up to 5 NFTs to increase your maximum allocations. In season 1, NFTs can be freely traded but are required to stake to invest. In subsequent seasons, NFTs are auto-staked for 6 months.
                </strong>
                <br />
              </p>
            </div>
          </div>
        </Reveal>
        <Reveal
          left
        >
          <div data-w-id="f3a8aefc-5526-160f-c494-f128c735fb65" className="faq bottom_faq">
            <div className="faq-answer-wrap">
              <h4 className="heading"><strong>How do I get whitelist?</strong></h4>
              <p className="paragraph">
                <strong>Whitelist is only for season 2 and beyond, and both holders and non-holders are eligible. Holders can get whitelisted through being active in the community, engaging on socials, attending AMAs, taking courses at the Taiji Labs Institute, referrals, and being an overall valuable member of the community. There will also be chances at whitelist raffles through whitelist collaborations.</strong>
                <br />
              </p>
            </div>
          </div>
        </Reveal>
        <Reveal
          left
        >
          <div data-w-id="ad95eaad-9eb3-0fab-a4bc-1af1fc641d65" className="faq bottom_faq lastfaq">
            <div className="faq-answer-wrap">
              <h4 className="heading"><strong>Where can I find more information on the Taiji Labs?</strong></h4>
              <p className="paragraph">
                <strong>
                  The Taiji Labs Institute is the educational hub of Taiji Labs that provides resources for holders to further professional growth in the web3 space and learn how to properly vet projects. Educational resources are carefully curated by our team to keep the community empowered and engaged.
                  <br />
                  <br />
                  Projects are rigorously vetted through our Taiji Process by our world-class researchers and also reviewed by our expert web3 advisors before being voted on by the community, who participate in all of the steps along the way with full transparency.
                  <br />
                  <br />
                  All members who wish to invest directly have to KYC. U.S. Citizens can either bypass KYC if they are an accredited investor or LLC, or they participate in our “raffle” system, which is a gamified way of still legally investing in projects. Which tier, how many NFTs they own, and how long they’ve staked will determine the odds of the U.S. non-KYC holder.
                </strong>
                <br />
              </p>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  );
});

export default FAQ;
