import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { store } from '../mobx/store';
import { getName } from '../utils/utils';
import BiddersList from './BiddersList';

const TopBidder = observer(() => {
  return (
    <BiddersList
      title="TOP BIDDERS"
      description="Top 10 bidders"
      data={store.stats?.topBidders}
    />
  );
});

export default TopBidder;
