import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { reaction } from 'mobx';
import Providers from './components/Providers';
import GlobalCSS from './GlobalStyle';
import { store } from './mobx/store';
import HomePage from './components/pages/HomePage';
import { tryForever } from './utils/utils';

const Main = () => {
  const ctx = useWeb3React();

  useEffect(() => {
    store.web3Ctx = ctx;
  }, [ctx, ctx.account]);

  useEffect(() => {
    store.fetchInfos();
    store.fetchStats();
    store.fetchVacancy();
    store.fetchUsersCountEnteredWaitlist();
    store.fetchUsersWillReceiveAirdrop();
    store.fetchSpace();
    const listener = () => {
      requestAnimationFrame(() => {
        store.setScrollYOffset(window.pageYOffset);
      });
    };
    window.addEventListener('scroll', listener);
    const resizeListener = () => {
      requestAnimationFrame(() => {
        store.setScreenSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      });
    };
    window.addEventListener('resize', resizeListener);
    const interval = setInterval(() => {
      store.fetchStats();
    }, 10000);
    return () => {
      window.removeEventListener('scroll', listener);
      window.removeEventListener('resize', resizeListener);
      clearInterval(interval);
    };
  }, []);

  const prevAcc = useRef();
  useEffect(() => {
    if (prevAcc.current && ctx.account && (prevAcc.current !== ctx.account)) {
      // store.logout();
      store.contractFetchedForUser = false;
      store.addingMoreProteges = false;
      store.fetchInfos();
    } else {
      store.fetchInfos();
    }
    if (
      !prevAcc.current && ctx.account && !store.hasValidSignature()
    ) {
      store.signInDialog = true;
    }
    prevAcc.current = ctx.account;
    // if (!ctx.account && location.pathname.length !== 1) {
    //   document.location.href = '/';
    // }
  }, [ctx.account, location.pathname]);

  useEffect(() => {
    const fn = async () => {
      if (store.ownBid === undefined && store.hasValidSignature()) {
        tryForever(async () => {
          await store.fetchOwnBid();
        }, 'fetchOwnBid');
      }
    };
    fn();
    return reaction(() => store.userSignature, fn);
  }, [ctx, ctx.account]);

  return (
    <HomePage />
  );
};

const routes = [
  { path: '/', name: 'Home', Component: Main},
];

const App = () => {
  return (
    <Providers>
      <GlobalCSS />
      <div className="background" />
      <BrowserRouter>
        <Route component={Main} />
      </BrowserRouter>
    </Providers>
  );
};

export default App;
