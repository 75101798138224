import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { now } from 'mobx-utils';
import { getTimeLeftObj, getTimeLeftStr } from './utils/utils';
import { NETWORK_CONFIG } from './utils/constants';
import { store } from './mobx/store';
import AuctionPart from './AuctionPart';
import { useConnect } from './hooks/hooks';
import AuctionFinishedPart from './components/AuctionFinishedPart';
import RefundAuctionPart from './components/RefundAuctionPart';
import { isWinnersReady } from './components/results';
import { Flex, VFlex } from './components/Flex';
import RefundWaitlistPart from './components/RefundWaitlistPart';

interface MintPartProps {
}

const MintPart = observer((props:MintPartProps) => {
  const n = now();
  const timeLeftObj = getTimeLeftObj(NETWORK_CONFIG.AUCTION_END_TS - n);
  const connect = useConnect(true);
  return (
    <div className="_40_percent_block">
      <h1><span className="text-span auction-font">Taiji Labs Blind Auction</span></h1>
      <p className="max_width_400">
        <strong className="bold-text-2">{`Place your bid in Taiji Labs Genesis Key Auction with a deposit of ${store.depositAmount || 0.15} ETH. Final Auction Price = the LOWEST bid from the TOP 288 bidders at the end of the auction.`}</strong>
      </p>
      {
        ((store.auctionState == 1 || store.auctionState == 2) && timeLeftObj)
            && (
              <VFlex>
                <h2 style={{marginBottom: -20}}>
                  {
                    store.auctionState == 1 ? 'Auction End In:' : 'Payment Period:'
                  }
                </h2>
                <div className="countdown-row">
                  <div className="countdown-block">
                    <div id="days" className="countdown-time">{`${timeLeftObj?.d || 0}`.padStart(2, '0')}</div>
                    <div className="countdown-detail">Days</div>
                  </div>
                  <div className="countdown-block">
                    <div id="hours" className="countdown-time">{`${timeLeftObj?.h || 0}`.padStart(2, '0')}</div>
                    <div className="countdown-detail">Hours</div>
                  </div>
                  <div className="countdown-block">
                    <div id="minutes" className="countdown-time">{`${timeLeftObj?.m || 0}`.padStart(2, '0')}</div>
                    <div className="countdown-detail">Minutes</div>
                  </div>
                  <div className="countdown-block">
                    <div id="seconds" className="countdown-time">{`${timeLeftObj?.s || 0}`.padStart(2, '0')}</div>
                    <div className="countdown-detail">Seconds</div>
                  </div>
                </div>
              </VFlex>
            )
      }
      {
        (() => {
          if (store.auctionState === -1) {
            return (
              <h5>
                Loading Auction...
              </h5>
            );
          }
          if (store.auctionState === 0) {
            return (
              <h5>
                Auction will start very soon
              </h5>
            );
          }
          let components = [];
          if (store.auctionState == 1) {
            components.push((
              <AuctionPart />
            ));
          }
          if (store.auctionState === 2) {
            components.push((
              <AuctionFinishedPart />
            ));
          }
          if (!store.web3Ctx?.account) {
            return (
              <div
                className="submit_button w-button"
                onClick={(evt) => {
                  connect();
                }}
              >
                CONNECT
              </div>
            );
          }
          components.push((
            <RefundAuctionPart />
          ));
          components.push((
            <RefundWaitlistPart />
          ));
          return components;
        })()
      }
    </div>
  );
});

export default MintPart;
