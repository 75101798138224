import { FixedNumber } from 'ethers';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { formatEther } from '@ethersproject/units';

import { NETWORK_CONFIG, PHASE, WIDTH_THRESHOLD } from './constants';

export const formatBN = bigNumber => parseFloat(formatEther(bigNumber));

export const getPlaceHolderURL = (size) => {
  return `https://via.placeholder.com/${size}`;
};

export const getAddressString = (addr:String) => {
  if (!addr) return '';
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 7, addr.length - 1)}`;
};

export const networkSetup = () => {
  return new Promise((resolve, reject) => {
    const provider = window.ethereum;
    if (provider) {
      // if (networkSettings.hasOwnProperty(chainId)) {
      console.log(NETWORK_CONFIG.id);
      provider
        .request({
          method: 'wallet_switchEthereumChain',
          params: [{chainId: NETWORK_CONFIG.id}],
        })
        .then(resolve)
        .catch(reject);
      // } else {
      //   reject(new Error(`No network settings configured for chainId: '${chainId}'`));
      // }
    } else {
      reject(new Error(`window.ethereum is '${typeof provider}'`));
    }
  });
};

export const useIsDesktop = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${WIDTH_THRESHOLD}px)`,
  });
  return isDesktopOrLaptop;
};

export function numberInGroups(n) {
  return n?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0;
}

export const getDisplayPriceString = (price, commas = true) => {
  if (!price) return '0';
  const n = FixedNumber.fromValue(price, 18, 'fixed').toString();
  if (!commas) return n;
  const [dec, point] = n.split('.');
  if (point === '0') return numberInGroups(dec);
  return [numberInGroups(dec), point.substr(0, 5)].join('.');
};

export const getTimeLeftObj = (timeLeftMS) => {
  if (timeLeftMS < 0) return null;
  const seconds = timeLeftMS / 1000;
  const units = ['y', 'd', 'h', 'm'];
  const values = [
    Math.floor(seconds / 31536000),
    Math.floor((seconds % 31536000) / 86400),
    Math.floor(((seconds % 31536000) % 86400) / 3600),
    Math.floor((((seconds % 31536000) % 86400) % 3600) / 60),
    Math.floor((((seconds % 31536000) % 86400) % 3600) % 60),
  ];
  return {
    y: Math.floor(seconds / 31536000),
    d: Math.floor((seconds % 31536000) / 86400),
    h: Math.floor(((seconds % 31536000) % 86400) / 3600),
    m: Math.floor((((seconds % 31536000) % 86400) % 3600) / 60),
    s: Math.floor((((seconds % 31536000) % 86400) % 3600) % 60),
  };
};

export const getTimeLeftStr = (timeLeftMS) => {
  if (timeLeftMS < 0) return null;
  const obj = getTimeLeftObj(timeLeftMS);
  const padder = (num) => {
    return `${num}`.padStart(2, '0');
  };
  return `${padder(obj.d)}d ${padder(obj.h)}:${padder(obj.m)}:${padder(obj.s)}`;
};

export function hexEncode(s: string) {
  let hex;
  let i;

  let result = '';
  for (i = 0; i < s.length; i++) {
    hex = s.charCodeAt(i).toString(16);
    if (hex.length === 1) {
      result += `0${hex}`;
    } else {
      result += `${hex}`;
    }
  }
  return result;
}

// export const interpolateFn = (a, b) => {
//   return (t) => {
//     return a * (1 - t) + b * t;
//   };
// };

export const interpolateFn = ({
  inputRange,
  outputRange,
}:{
  inputRange: [number],
  outputRange: [number],
}) => {
  const il = inputRange.length;
  return (currentValue) => {
    // console.log('call interpolate');
    for (let i = 0; i < il; i++) {
      if (currentValue === inputRange[i]) return outputRange[i];
      if (currentValue < inputRange[i]) {
        if (i === 0) return outputRange[i];
        const lowerIn = Math.min(inputRange[i - 1], inputRange[i]);
        const upperIn = Math.max(inputRange[i - 1], inputRange[i]);
        const progress = Math.abs((currentValue - lowerIn) / (upperIn - lowerIn));
        return (outputRange[i] - outputRange[i - 1]) * progress + outputRange[i - 1];
      }
    }
    return outputRange[il - 1];
  };
};

export const genNumArray = (from, to) => {
  const arr = [];
  for (let i = from; i <= to; i++) {
    arr.push(i);
  }
  return arr;
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

let tryForeverSessions = {};

export const tryForever = async (fn, id) => {
  let i = 0;
  let session = Math.random();
  tryForeverSessions[id] = session;
  console.log('[tryForever] Start', id, session);
  while(true) {
    if (tryForeverSessions[id] !== session) {
      console.log('[tryForever] Bail', id, session);
      break;
    }
    try {
      // eslint-disable-next-line no-await-in-loop
      await fn();
      break;
    } catch (error) {
      console.error('[tryForever] E', id, session, i, error);
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep((++i ** 1.5) * 1000);
  }
  console.log('[tryForever] Finish', id, session);
};

export const getName = (b) => {
  let name = getAddressString(b.address);
  if (b.ens && b.ens.name && b.ens.name !== '0x0000000000000000000000000000000000000000') {
    name = b.ens.name;
  } else if (b.username) {
    name = b.username;
  }
  return name;
};
