import React, {Component, useState} from 'react';
import {observer} from 'mobx-react';
import { parseEther } from 'ethers/lib/utils';
import { store } from './mobx/store';
import { useConnect } from './hooks/hooks';
import { BTN_CLASS, CONFIRM_TX, PROCESSING_TX } from './utils/constants';

interface AuctionPartProps {
}

const AuctionPart = observer((props:AuctionPartProps) => {
  const connect = useConnect(true);
  const [bidInput, setBidInput] = useState('');
  const [isDepositLoading, setIsDepositLoading] = useState();
  let bt = bidInput.toString().trim();
  const bidInputNum = parseFloat(bt);
  const [isLoading, setIsLoading] = useState();
  const oldBidNum = parseFloat(store.ownBid.amount || '0');
  const [msg, setMsg] = useState('* You can only raise your bid once placed.');
  const [err, setErr] = useState();
  let errorMsg = '';
  let errorMsg2 = '';
  let canBid = true;
  if (bt.length === 0) {
    canBid = false;
  } else if (isNaN(bidInputNum) || bt.split('.').length > 2 || !bt.match(/^[0-9.]+$/)
  ) {
    canBid = false;
    errorMsg = 'Input must be a number.';
  } else if (bidInputNum <= oldBidNum) {
    canBid = false;
    errorMsg2 = `(Must be more than ${oldBidNum} ETH)`;
  }
  if (err) {
    errorMsg = store.getErrorMessage(err);
  }
  const onPlaceBidClick = async (evt) => {
    evt.preventDefault();
    if (isLoading || !store.isDepositedAny || !canBid) {
      console.log(store.isDepositedAny);
      return;
    }
    setErr(null);
    if (!parseFloat(bidInputNum)) {
      return;
    }
    if (bidInputNum <= oldBidNum) {
      return;
    }
    const result = confirm(`You bid: ${bidInput} ETH.\n* You can only raise your bid once placed.`);
    if (!result) return;
    setIsLoading(true);
    try {
      const d = parseEther(`${bidInput}`);
      await store.fetchUpdateBid(d.toString());
      store.fetchStats();
      setBidInput('');
      setMsg('Placed Bid');
    } catch (error) {
      setErr(error);
    }
    setIsLoading(false);
  };
  const onDepositClick = async () => {
    if (isDepositLoading || !store.contractFetchedForUser) return;
    setErr(null);
    setIsDepositLoading(true);
    try {
      const depositAmount = parseEther(store.depositAmount);
      if (!store.isDepositedAny) {
        let gasLimit = await store.auctionContract.estimateGas.deposit({
          value: depositAmount,
        });
        setMsg(CONFIRM_TX);
        const res = await store.auctionContract.deposit({
          value: depositAmount,
          gasLimit: Math.ceil(gasLimit.toNumber() * 1.5),
        });
        setMsg(PROCESSING_TX);
        await res.wait();
        setMsg('Deposit Sucess.');
        store.isDeposited = true;
      }
    } catch (error) {
      // store.hideToast(confirmToastHandle);
      if (error.code === 'INSUFFICIENT_FUNDS') {
        // store.showErrorToast(new Error('Insufficient Funds'));
        alert('INSUFFICIENT FUNDS');
        setMsg('');
      } else {
        setErr(error);
        // store.showErrorToast(error);
      }
    }
    setIsDepositLoading(false);
  };
  console.log('auction render');
  if (!store.web3Ctx?.account) {
    console.log('adsf');
    return (
      <div
        className={`${BTN_CLASS} simple-btn`}
        onClick={(evt) => {
          connect();
        }}
      >
        CONNECT
      </div>
    );
  }
  if (!store.hasValidSignature()) {
    return (
      <div
        className={`${BTN_CLASS} simple-btn`}
        onClick={(evt) => {
          store.signByMetamask();
        }}
      >
        SIGN IN
      </div>
    );
  }
  if (!store.isDeposited) {
    return (
      <div>
        <div
          className="submit_button w-button"
          onClick={onDepositClick}
        >
          {!store.contractFetchedForUser || isDepositLoading ? 'LOADING...' : 'Deposit'}
        </div>
        {msg}
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className="div-block-10">
        <h4 className="my-bid">Your current bid: </h4>
        <div className="symbol">
          {store.ownBid.amount || '0'}
          {' '}
          ETH
        </div>
      </div>
      <div className="form-block w-form">
        <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form">
          <input
            style={{color: 'white'}}
            type="text"
            className="form_field w-input"
            maxLength="256"
            name="Your-Bid"
            data-name="Your Bid"
            placeholder="Input bid here"
            id="Your-Bid"
            required=""
            onChange={(evt) => {
              setBidInput(evt.target.value);
            }}
          />
          <div className="text-block-3">ETH</div>
          <input
            type="submit"
            value="Raise your bid"
            data-wait="Please wait..."
            className="submit_button w-button"
            onClick={onPlaceBidClick}
          />
        </form>
      </div>
      {
        errorMsg.length > 0 && (
          <div style={{color: 'red'}}>
            {errorMsg}
          </div>
        )
      }
      <div style={{color: 'red'}}>
        {errorMsg2}
      </div>
      <div style={{color: 'white'}}>
        {msg}
      </div>
    </React.Fragment>
  );
});

export default AuctionPart;
