import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { AnimatePresence, motion, usePresence } from 'framer-motion/dist/framer-motion';

import { store } from '../mobx/store';
import { getName } from '../utils/utils';
import {TBidder} from '../types';
import { Flex } from './Flex';


interface TopBidderProps extends TBidder {
  isSimple: Boolean,
}

export const BidderListItem = observer((props:TBidder) => {
  const {amount, isSimple} = props;
  const [isPresent, safeToRemove] = usePresence();
  const animations = {
    layout: true,
    initial: 'out',
    style: {
      position: (isPresent) ? 'relative' : 'absolute',
    },
    animate: isPresent ? 'in' : 'out',
    whileTap: 'tapped',
    variants: {
      in: { opacity: 1 },
      out: { opacity: 0, scale: 1},
      tapped: { opacity: 0.5, transition: { duration: 0.1 } },
      hover: { opacity: 1 },
    },
    whileHover: 'hover',
    onAnimationComplete: () => !isPresent && safeToRemove(),
    // transition: { type: 'spring', stiffness: 500, damping: 50, mass: 1 },
  };
  return (
    <motion.div
      {...animations}
      // id="w-node-_4cbd87cf-5e42-2165-f477-ffa62d5e35f1-8903aa5a"
      className="team-card"
    >
      <img
        style={{
          width: isSimple ? 50 : 150,
          height: isSimple ? 50 : 150,
          borderRadius: isSimple && 25,
        }}
        src={props.account?.profile_img_url || '/images/white_icon.png'}
        loading="lazy"
        alt=""
        className="image"
      />
      {
        !isSimple && (
          <div className="team-member-name">{getName(props)}</div>
        )
      }
      {
        amount && (
          <div className="team-member-position">
            {parseFloat(amount).toFixed(2)}
            {' '}
            ETH
          </div>
        )
      }
    </motion.div>
  );
});
interface BiddersListProps {
  title: String,
  description: String,
  data: TBidder[],
  isSimple: Boolean,
}

export const LatestList = observer((props:BiddersListProps) => {
  const {title, description, data, isSimple} = props;
  return (
    <section id="Auction" className="team top-bidder wf-section">
      <div className="team-committee">
        <h2>{title}</h2>
        <p className="centered-subheading">{description}</p>
        <Flex style={{flexWrap: 'wrap', gap: 12}}>
          <AnimatePresence
            initial={false}
            key={`recent-bidder-list${title}`}
          >
            {
              data?.map((topBidder) => {
                return (
                  <BidderListItem
                    isSimple={isSimple}
                    key={topBidder.address}
                    {...topBidder}
                  />
                );
              })
            }
          </AnimatePresence>
        </Flex>
        {store.stats.latestBidders[0] && (
          <Flex
            style={{marginTop: 20}}
          >
            {getName(store.stats.latestBidders[0])}
            {' '}
            and
            {' '}
            {store.stats && Math.max(store.stats.participants - 1, 1)}
            {' '}
            others placed a bid
          </Flex>
        )}
      </div>
    </section>
  );
});

const BiddersList = observer((props:BiddersListProps) => {
  const {title, description, data, isSimple} = props;
  return (
    <section id="Auction" className="team top-bidder wf-section">
      <div className="team-committee">
        <h2 className="heading-2">{title}</h2>
        <p className="centered-subheading">{description}</p>
        <div className="team-grid">
          <AnimatePresence
            initial={false}
            key={`recent-bidder-list${title}`}
          >
            {
              data?.map((topBidder) => {
                return (
                  <BidderListItem
                    isSimple={isSimple}
                    key={topBidder.address}
                    {...topBidder}
                  />
                );
              })
            }
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
});

export default BiddersList;
