import React, { Component, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useWeb3React } from '@web3-react/core';
import { useConnect } from '../../hooks/hooks';
import { store } from '../../mobx/store';
import PartnersSection from '../PartnersSection';
import ExclusiveCommunitySection from '../ExclusiveCommunitySection';
import Team from '../Team';
import FAQ from '../FAQ';
import TopBidder from '../TopBidder';
import Services from '../Services';
import HeaderMenu from '../HeaderMenu';
import OverlayMenu from '../OverlayMenu';
import MintPart from '../../MintPart';
import VideoDialog from '../dialog/VideoDialog';
import BiddersList, { LatestList } from '../BiddersList';

interface HomePageProps {
}

const HomePage = observer((props: HomePageProps) => {
  const [hasClaimed, setHasClaimed] = useState(false); // CLICKED, TX_SENT, TX_CONFIRMED
  const ctx = useWeb3React();
  useEffect(() => {
    (async () => {
      if (store.myWhitelist && store.mintContract) {
        setHasClaimed(await store.mintContract.hasClaimed(ctx.account));
      }
    })();
  }, [store.myWhitelist, store.mintContract, ctx.account]);

  return (
    <React.Fragment>
      <div id="Home" className="section hero-section wf-section" style={{marginBottom: 0, paddingBottom: 0}}>
        <div data-collapse="tiny" data-animation="default" data-duration="400" data-w-id="6570dc26-103f-0bb1-d183-fa6882f52157" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
          <div className="nav-container">
            <a href="index.html" aria-current="page" className="brand w-nav-brand w--current"><img width="70" sizes="(max-width: 479px) 25vw, (max-width: 767px) 10vw, (max-width: 991px) 7vw, 70px" alt="logo" src="images/white_icon.png" srcSet="images/white_icon-p-500.png 500w, images/white_icon.png 632w" className="logo" /></a>
            <HeaderMenu />
            <div className="menu-button w-hidden-main w-hidden-medium w-hidden-small w-hidden-tiny w-nav-button">
              <div className="w-icon-nav-menu" />
            </div>
            <div
              data-w-id="6570dc26-103f-0bb1-d183-fa6882f52172"
              className="mobile-hamburger-wrapper w-hidden-main"
              onClick={() => {
                store.isShowOverlayMenu = true;
              }}
            >
              <div className="hamburger-line" />
              <div className="hamburger-line" />
              <div className="hamburger-line" />
            </div>
          </div>
        </div>
        <img
          src="images/fade.png"
          loading="lazy"
          width="1737"
          srcSet="images/fade-p-500.png 500w, images/fade-p-800.png 800w, images/fade-p-1080.png 1080w, images/fade-p-1600.png 1600w, images/fade.png 1960w"
          sizes="(max-width: 767px) 92vw, (max-width: 991px) 90vw, (max-width: 1439px) 88vw, 100vw"
          alt=""
          className="image-3"
        />
        <div data-w-id="ab985314-bbcb-433f-2e3c-fae0ccee923a" className="wrapper flex">
          <MintPart />
          <div
            className="grow_if_possible"
            style={{pointerEvents: 'none'}}
            onClick={() => {
              // store.isShowVideoDialog = true;
            }}
          >
            <a href="/#" className="lightbox-link w-inline-block w-lightbox">
              <img src="images/Key2.gif" width="426" alt="" />
            </a>
          </div>
        </div>
      </div>
      <Services />
      {
        store.stats?.latestBidders?.length > 0 && (
          <LatestList
            title="Latest Bidders"
            description=""
            isSimple
            data={store.stats?.latestBidders}
          />
        )
      }
      <TopBidder />
      <FAQ />
      <Team />
      <ExclusiveCommunitySection />
      <PartnersSection />
      <div>
        <h4 className="heading-3">Designed by Taiji Labs, Copyright 2022</h4>
      </div>
      <OverlayMenu />
      <VideoDialog />
    </React.Fragment>
  );
});

export default HomePage;
