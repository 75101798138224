import React from 'react';
import { createWeb3ReactRoot, getWeb3ReactContext, Web3ReactProvider } from '@web3-react/core';
import { BrowserRouter } from 'react-router-dom';
import { Web3Provider } from '@ethersproject/providers';

import { networkConnector } from '../hooks/hooks';


function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 15000;
  return library;
}

function getNetworkLibrary(): Web3Provider {
  // eslint-disable-next-line no-return-assign
  return new Web3Provider(networkConnector.provider);
}

let Web3ProviderNetwork;
try {
  Web3ProviderNetwork = getWeb3ReactContext('NETWORK');
} catch (error) {
  Web3ProviderNetwork = createWeb3ReactRoot('NETWORK');
}

const Providers: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getNetworkLibrary}>
          {children}
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </BrowserRouter>
  );
};

export default Providers;
