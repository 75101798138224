import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { Reveal } from 'react-reveal';

interface ServicesProps {
}

const Services = observer((props:ServicesProps) => {
  return (
    <div id="Services" className="section dark_blue_bg wf-section">
      <div className="wrapper">
        <div className="w-layout-grid _3_col_grid">
          <Reveal>
            <div data-w-id="a84a51f4-297a-c772-f4cc-dce16efb8e48" className="card">
              <h3 className="heading-5"><strong>WHITELIST &amp; AIRDROP</strong></h3>
              <p>
                <strong>Direct </strong>
                <span className="highlight-words"><strong>Earpitz </strong></span>
                <strong>NFT airdrop and whitelist to </strong>
                <span className="highlight-words"><strong>Earpitz </strong></span>
                <strong>, </strong>
                <span className="highlight-words"><strong>YogaPetz</strong></span>
                <strong>, </strong>
                <span className="highlight-words"><strong>Oldeus </strong></span>
                <strong>&amp; </strong>
                <span className="highlight-words"><strong>KubZ</strong></span>
                <strong>. High-quality future whitelist opportunities and Token airdrop.</strong>
              </p>
            </div>
          </Reveal>
          <Reveal>
            <div data-w-id="a84a51f4-297a-c772-f4cc-dce16efb8e50" className="card">
              <h3 className="heading-6"><strong>INVESTMENT OPPORTUNITIES</strong></h3>
              <p>
                <strong>Exclusive investment opportunities in a democratized and decentralized way through blockchain technology. </strong>
                <span className="highlight-words"><strong>Instant unlock</strong></span>
                <strong> first investment project after auction.</strong>
              </p>
            </div>
          </Reveal>
          <Reveal>
            <div data-w-id="a84a51f4-297a-c772-f4cc-dce16efb8e58" className="card">
              <h3 className="heading-7"><strong>NETWORKING &amp; EDUCATIONAL INSTITUTE</strong></h3>
              <p>
                <strong>Part of an open </strong>
                <span className="highlight-words"><strong>ecosystem</strong></span>
                <strong> with the ability to talk directly to </strong>
                <span className="highlight-words"><strong>founders of projects</strong></span>
                <strong> and be a part of the vetting process and nominate projects. Weekly townhall and AMA</strong>
              </p>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  );
});

export default Services;
