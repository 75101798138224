import React, {Component} from 'react';
import {observer} from 'mobx-react';

interface ExclusiveCommunitySectionProps {
}

const ExclusiveCommunitySection = observer((props:ExclusiveCommunitySectionProps) => {
  return (
    <section className="features-metrics wf-section">
      <h1 className="heading-2">Exclusive Community</h1>
      <div className="text-block">Where the team holds:</div>
      <div className="container-2">
        <div className="features-wrapper">
          <div className="features-block">
            <div className="features-title">$240m+</div>
            <div>Managed Fund</div>
          </div>
          <div className="features-block">
            <div className="features-title">80+</div>
            <div>
              <strong>
                CryptoPunks
                <br />
              </strong>
            </div>
          </div>
          <div className="features-block">
            <div className="features-title"><strong>160+</strong></div>
            <div>
              <strong>
                CyberKongz &amp; VX
                <br />
              </strong>
            </div>
          </div>
          <div className="features-block">
            <div className="features-title"><strong>520+</strong></div>
            <div>
              <strong>
                Azuki &amp; BEANZ
                <br />
              </strong>
            </div>
          </div>
          <div className="features-block">
            <div className="features-title"><strong>330+</strong></div>
            <div>
              <strong>
                Pudgy Penguins
                <br />
              </strong>
            </div>
          </div>
          <div className="features-block">
            <div className="features-title">5</div>
            <div>VCs</div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default ExclusiveCommunitySection;
