import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { isWinnersReady } from './results';
import { store } from '../mobx/store';
import JoinWaitListPart from './JoinWaitListPart';
import { useConnect } from '../hooks/hooks';
import WinnerPart from './WinnerPart';
import { Flex, VFlex } from './Flex';

interface AuctionFinishedPartProps {
}

const AuctionFinishedPart = observer((props:AuctionFinishedPartProps) => {
  const connect = useConnect(true);
  const usersWillReceiveAirdrop = parseInt(store.usersWillReceiveAirdrop, 10);
  const usersCountEnteredWaitlist = parseInt(store.usersCountEnteredWaitlist, 10);
  return (
    <div>
      <h3 style={{marginTop: 0}}>Auction has ended.</h3>
      <Flex style={{gap: store.isDesktop ? 50 : 0, justifyContent: store.isDesktop ? 'center' : 'space-evenly', paddingTop: 20, paddingBottom: 10}}>
        <VFlex style={{alignItems: 'center', gap: 5}}>
          <div style={{fontSize: 35}}>{usersWillReceiveAirdrop}</div>
          <span>Winners</span>
        </VFlex>
        <VFlex style={{alignItems: 'center', gap: 5}}>
          <div style={{fontSize: 35}}>{usersCountEnteredWaitlist}</div>
          <span>Reserved</span>
        </VFlex>
        {(() => {
          if (true) {
            return (
              <VFlex style={{alignItems: 'center', gap: 5}}>
                <div style={{fontSize: 35}}>{store.waitlistVacancy}</div>
                <span>Vacancy</span>
              </VFlex>
            );
          }
          return '';
        })()}
      </Flex>
      {
        (() => {
          if (!isWinnersReady) {
            return 'Auction is concluding. We will update the results ASAP';
          }
          if (!store.web3Ctx?.account) {
            return (
              <div
                className="submit_button w-button"
                onClick={(evt) => {
                  connect();
                }}
              >
                CONNECT
              </div>
            );
          }
          if (!store.isDepositedAny) {
            return (
              <React.Fragment>
                <JoinWaitListPart />
              </React.Fragment>
            );
          }
          if (store.isWinner) {
            return (
              <WinnerPart />
            );
          }
          return (
            <React.Fragment>
              Oops you are out of the highest bids.
              <JoinWaitListPart />
            </React.Fragment>
          );
        })()
      }
    </div>
  );
});

export default AuctionFinishedPart;
