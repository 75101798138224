import React, {Component, useState} from 'react';
import {observer} from 'mobx-react';
import { formatEther } from 'ethers/lib/utils';
import { Flex } from 'rebass';
import { store } from '../mobx/store';
import { VFlex } from './Flex';
import { BTN_CLASS, CONFIRM_TX, PROCESSING_TX } from '../utils/constants';

interface JoinWaitListPartProps {
}

const JoinWaitListPart = observer((props:JoinWaitListPartProps) => {
  const [isLoading, setIsLoading] = useState();
  const [message, setMessage] = useState();
  if (store.auctionState === 3) return null;
  return (
    <VFlex style={{gap: 12}}>
      <VFlex style={{marginTop: 12}}>
        <h3 style={{marginTop: 0, marginBottom: -20}}>
          {`Reserve Waitlist (${formatEther(store.finalPrice || '0')} ETH)`}
        </h3>
        <br />
        <span style={{fontSize: 12, marginTop: 3}}>
          {`Vacancy: ${store.waitlistVacancy}`}
        </span>
        <div style={{fontSize: 12, marginTop: 8}}>
          <div style={{opacity: 0.8}}>
            * You will be on the sublist if any winners failed to pay, first come first serve
            <br />
            * You can only reserve waitlist when there is vacancy
            <br />
            * If you are not selected, your deposit will be refunded
          </div>
        </div>
      </VFlex>
      <Flex style={{alignItems: 'center'}}>
        <div
          className={BTN_CLASS}
          onClick={async () => {
            if (isLoading) return;
            setIsLoading(true);
            try {
              await store.waitlistContract.estimateGas.enterWaitlist({
                value: store.finalPrice,
              });
              const res = await store.waitlistContract.enterWaitlist({
                value: store.finalPrice,
              });
              setMessage(CONFIRM_TX);
              await res.wait();
              setMessage(PROCESSING_TX);
              store.isUserDepositedWaitlist = true;
            } catch (error) {
              let m = store.getErrorMessageWithHeader(error);
              setMessage(`${m}`);
            }
            setIsLoading(false);
          }}
        >
          {
            (() => {
              if (isLoading) return 'Loading...';
              return (store.isUserDepositedWaitlist ? 'Reserved' : 'Reserve');
            })()
          }
        </div>
        {
          (store.isUserDepositedWaitlist) && (
            <span>
              <img
                style={{transform: 'translateY(-1px)', marginLeft: 8}}
                src="/images/circle-tick.svg"
                alt=""
              />
            </span>
          )
        }
      </Flex>
      {
        message && (
          <div style={{color: message.startsWith('Error') && 'red'}}>
            {message}
          </div>
        )
      }
    </VFlex>
  );
});

export default JoinWaitListPart;
