import { action, computed, makeAutoObservable, makeObservable, observable } from 'mobx';
// eslint-disable-next-line import/no-unresolved
import type { Web3ReactContextInterface } from '@web3-react/core/dist/types';

import { BigNumber, ethers } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { get, post } from '../api/api';
import { DAY_MS, NETWORK_CONFIG, WIDTH_SHORTEN_THRESHOLD, WIDTH_THRESHOLD, XS_WIDTH } from '../utils/constants';
import { hexEncode } from '../utils/utils';
import { getContract } from '../hooks/useContract';
import {Stats, TBidder} from '../types';
import AUCTION_ABI from '../abi/auction.json';
import WAITLIST_ABI from '../abi/waitlist.json';
import { WAITLIST, WINNERS } from '../components/results';


const Web3ContractETH = require('web3-eth-contract');
const whitelist = require('../whitelist.json');
const waitlist = require('../waitlist.json');

class Store {
  isIncorrectNetwork = false;

  web3Ctx: Web3ReactContextInterface = null

  mintContract = null

  maxSupply = 9999

  totalSupply = 0

  maxMintPerAddress = 0

  userSignature: { dt: string, signature: string } = null

  wlStatus = undefined

  scrollYOffset = 0

  countdownToAllowlist = ' '; // non-null empty

  countdownToWaitlist = ' '; // non-null empty

  section1Size = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  }

  toasts: any[] = []

  screenSize = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  }

  auctionState = -1

  isDeposited = false

  get isDepositedAny() {
    console.log('this.isDeposited', this.isDeposited);
    return this.isDeposited || this.ownBid?.verified;
  }

  finalPrice = '0'

  isUserRefunded = false

  isUserWillReceiveAirdrop = false

  isUserDepositedWaitlist = false

  isUserRefundedWaitlist = false

  contractFetchedForUser = false

  setScreenSize(s) {
    this.screenSize = s;
  }

  setScrollYOffset(s) {
    this.scrollYOffset = s;
  }

  get isShortenMode() {
    return this.screenSize.width < WIDTH_SHORTEN_THRESHOLD;
  }

  get isDesktop() {
    return this.screenSize.width > WIDTH_THRESHOLD;
  }

  get myWhitelist() {
    if (!this.web3Ctx) return false;
    return whitelist[`${this.web3Ctx.account}`.toLowerCase()] || waitlist[`${this.web3Ctx.account}`.toLowerCase()];
  }

  get isXS() {
    return this.screenSize.width < XS_WIDTH;
  }

  get NotConnectedAuctionContract() {
    return new Web3ContractETH(AUCTION_ABI, NETWORK_CONFIG.auctionAddress);
  }

  get NotConnectedWaitlistContract() {
    return new Web3ContractETH(WAITLIST_ABI, NETWORK_CONFIG.waitlistAddress);
  }

  getContract(address, ABI) {
    const library = this.web3Ctx?.library;
    const account = this.web3Ctx?.account;
    if (!library || !account) return null;
    try {
      return getContract(address, ABI, library, account);
    } catch (error) {
      console.error('Failed to get contract', error);
    }
    return null;
  }

  async contractCallWrapper(cc, ncc, method, params = [], settings = {}) {
    let v;
    if (cc) {
      v = await cc[method](...params);
      if (!settings.raw && v.toNumber) {
        v = v.toNumber();
        if (settings.parseInt) {
          v = parseInt(v, 10);
        }
      }
    } else {
      v = await ncc.methods[method](...params).call();
      if (typeof v === 'string' && settings.toBigNumber) {
        v = BigNumber.from(v);
      }
    }
    return v;
  }

  stats:Stats = null

  async fetchStats() {
    const r = await get({
      uri: '/auction/stats',
    });
    // let testBidders:TBidder[] = [];
    // if (this.stats?.latestBidders) {
    //   testBidders = this.stats.latestBidders.slice();
    // }
    // testBidders.unshift({
    //   address: `${Date.now()}`,
    // });
    // if (testBidders.length > 10) {
    //   testBidders.splice(-1, 1);
    // }
    this.stats = r.body;
    // this.stats.latestBidders = testBidders;
    if (this.finalPrice === '0') {
      this.finalPrice = this.stats.contract.finalPrice;
    }
    if (this.auctionState === -1) {
      this.auctionState = parseInt(this.stats.contract.auctionState, 10);
    }
    this.fetchVacancy();
    this.fetchUsersWillReceiveAirdrop();
    this.fetchUsersCountEnteredWaitlist();
  }

  ownBidMap = {}

  get ownBid() {
    return this.ownBidMap[this.web3Ctx?.account] || 0;
  }

  async fetchOwnBid() {
    let addr = this.web3Ctx.account;
    let sig = this.hasValidSignature();
    if (!sig) return;
    const { body } = await post({
      uri: '/auction/my-bid',
      body: {
        address: addr,
        signature: sig.signature,
        msg: sig.dt,
      },
    });
    this.ownBidMap[addr] = body;
  }

  async fetchUpdateBid(amount) {
    let addr = this.web3Ctx.account;
    let sig = this.hasValidSignature();
    if (!sig) return;
    const { body } = await post({
      uri: '/auction/place-bid',
      body: {
        amount,
        address: addr,
        signature: sig.signature,
        msg: sig.dt,
      },
    });
    this.ownBidMap[addr] = body;
  }

  get isWinner() {
    if (!this.web3Ctx || !this.web3Ctx.account) return false;
    if (WINNERS[this.web3Ctx.account.toLowerCase()]) return true;
    if (WAITLIST[this.web3Ctx.account.toLowerCase()]) return true;
    return false;
    // const idx = this.usersEnteredWaitlist.indexOf(this.web3Ctx.account.toLowerCase());
    // if (idx === -1) return false;
    // return (idx + 1) <= this.waitlistSpace;
  }

  async fetchSpace() {
    let v = await this.waitlistContractCall('getSpace');
    this.waitlistSpace = parseInt(v, 10);
  }

  async fetchVacancy() {
    let v = await this.waitlistContractCall('getVacancy');
    this.waitlistVacancy = parseInt(v, 10);
  }

  usersWillReceiveAirdrop = 0;

  waitlistVacancy = 0;

  usersCountEnteredWaitlist = 0;

  async fetchUsersWillReceiveAirdrop() {
    let v = await this.auctionContractCall('getUsersWillReceiveAirdrop');
    this.usersEnteredWaitlist = v;
    this.usersWillReceiveAirdrop = parseInt(v.length, 10);
  }

  async fetchUsersCountEnteredWaitlist() {
    let v = await this.waitlistContractCall('getUsersCountEnteredWaitlist');
    this.usersCountEnteredWaitlist = v;
  }

  get auctionContract() {
    return this.getContract(NETWORK_CONFIG.auctionAddress, AUCTION_ABI);
  }

  get waitlistContract() {
    return this.getContract(NETWORK_CONFIG.waitlistAddress, WAITLIST_ABI);
  }

  async auctionContractCall(method, params = [], settings = {}) {
    return this.contractCallWrapper(this.auctionContract, this.NotConnectedAuctionContract, method, params, settings);
  }

  async waitlistContractCall(method, params = [], settings = {}) {
    return this.contractCallWrapper(this.waitlistContract, this.NotConnectedWaitlistContract, method, params, settings);
  }

  userSignatureMap: { [address: string]: { dt: string, signature: string } } = {}

  hasValidSignature = (forceUpdate = false) => {
    if (!this.web3Ctx?.account) return false;
    let addr = this.web3Ctx?.account;
    let sig = this.userSignatureMap[addr];
    if (forceUpdate || !sig) {
      const item = localStorage.getItem(`cache:signature:${addr}`);
      if (item) {
        this.userSignatureMap[addr] = JSON.parse(item);
        sig = this.userSignatureMap[addr];
      }
    }
    if (!sig) return false;
    const ts = parseInt(sig.dt.split('\n').slice(-1)[0], 10);
    let valid = Date.now() - ts < DAY_MS * 30;
    if (!valid) return false;
    const recoveredAddress = ethers.utils.verifyMessage(sig.dt, sig.signature);
    if (recoveredAddress !== addr) return false;
    return sig;
  }

  signByMetamask = async (extraMessage = 'Welcome to Taiji Labs.\nClick "Sign" to continue.') => {
    const acc = this.web3Ctx.account;
    let sig = this.hasValidSignature(true);
    if (sig) return sig;
    const dt = `${extraMessage ? `${extraMessage}\n\nTimestamp:\n` : ''}${Date.now()}`;
    // const dt = `${Date.now()}`;
    const msg = hexEncode(dt);
    const t = await this.web3Ctx.library.provider.request({
      method: 'personal_sign',
      params: [msg, acc],
    });
    sig = {
      dt,
      signature: t,
    };
    this.userSignatureMap[acc] = sig;
    localStorage.setItem(`cache:signature:${acc}`, JSON.stringify(sig));
    return sig;
  }

  depositAmount = '0.15'

  async fetchInfos() {
    {
      const results = await Promise.all([
        this.auctionContractCall('getFinalPrice', [], { raw: true }),
        this.auctionContractCall('auctionState', [], { parseInt: true }),
        this.auctionContractCall('DEPOSIT_AMOUNT', [], { raw: true, toBigNumber: true }),
      ]);
      this.finalPrice = results[0];
      // this.auctionState = 1;
      this.auctionState = parseInt(results[1], 10); // TODO: change after testing
      this.depositAmount = formatEther(results[2]);
    }
    if (this.web3Ctx?.account) {
      this.fetchOwnBid();
      const results = await Promise.all([
        this.auctionContractCall('isUserDeposited', [this.web3Ctx.account]),
        this.auctionContractCall('isUserRefunded', [this.web3Ctx.account]),
        this.auctionContractCall('isUserWillReceiveAirdrop', [this.web3Ctx.account]),
        this.waitlistContractCall('isUserDeposited', [this.web3Ctx.account]),
        this.waitlistContractCall('isUserRefunded', [this.web3Ctx.account]),
      ]);
      this.isDeposited = results[0];
      this.isUserRefunded = results[1];
      this.isUserWillReceiveAirdrop = results[2];
      this.isUserDepositedWaitlist = results[3];
      this.isUserRefundedWaitlist = results[4];
      this.contractFetchedForUser = true;
    }
  }

  getErrorMessage(error: any) {
    if (!error) return '';
    let message = error.message;
    const match = message.match(/"message":"([^"]+)"/i);
    if (match) {
      message = match[1];
    }
    if (error.data) {
      return `${message} | ${error.data.message} (code: ${error.data.code})`;
    } if (error.statusCode) {
      return message;
    } if (error.code === 4001) {
      return 'User denied transaction.';
    } if (error.code) {
      return message;
    }
    return `${error}`;
  }

  getErrorMessageWithHeader(error: any) {
    let m = this.getErrorMessage(error);
    if (!m.toLowerCase().startsWith('error')) {
      m = `Error: ${m}`;
    }
    return m;
  }

  showErrorToast(error: any) {
    if (!error) return;
    let message = error.message;
    const match = message.match(/"message":"([^"]+)"/i);
    if (match) {
      message = match[1];
    }
    if (error.data) {
      this.showToast({
        title: `Error ${error.code}`,
        description: `${message} | ${error.data.message} (code: ${error.data.code})`,
        type: 'danger',
        ttl: 2000,
      });
    } else if (error.statusCode) {
      this.showToast({
        title: `Error ${error.statusCode} - ${error.error}`,
        description: message,
        type: 'danger',
        ttl: 2000,
      });
    } else if (error.code === 4001) {
      this.showToast({
        title: 'User denied transaction.',
        description: '',
        type: 'danger',
        ttl: 2000,
      });
    } else if (error.code) {
      this.showToast({
        title: `Error ${error.code}`,
        description: message,
        type: 'danger',
        ttl: 2000,
      });
    } else {
      this.showToast({
        title: `${error}`,
        type: 'danger',
        ttl: 2000,
      });
    }
  }

  isShowVideoDialog = false

  updateSupply = async () => {
    if (!this.mintContract) return;
    this.maxSupply = await this.mintContract.MAX_SUPPLY();
    this.totalSupply = await this.mintContract.totalSupply();
  }

  isShowOverlayMenu = false

  logout() {
    localStorage.clear();
    location.reload();
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const store = new Store();
