import React, {Component, useState} from 'react';
import {observer} from 'mobx-react';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { store } from '../mobx/store';
import { Flex, VFlex } from './Flex';
import { WINNERS } from './results';
import { BTN_CLASS, CONFIRM_TX, PROCESSING_TX } from '../utils/constants';

interface WinnerPartProps {
}

const WinnerPart = observer((props:WinnerPartProps) => {
  const [isPayingDiff, setIsPayingDiff] = useState(false);
  const [message, setMessage] = useState();
  const s = store.finalPrice.sub(parseEther(store.depositAmount));
  return (
    <VFlex>
      <VFlex
        style={{gap: 12}}
      >
        <h3 style={{margin: '-5px 0px'}}>
          Congratulations! You have won the auction!
        </h3>
        {(() => {
          if (store.isUserWillReceiveAirdrop) {
            return (
              <span>
                You are in the Taiji Genesis Airdrop list.
              </span>
            );
          }
          if (store.auctionState === 2 && !store.isUserWillReceiveAirdrop) {
            return (
              <React.Fragment>
                <span>
                  Please pay the difference to mint, or you will lose your
                  {' '}
                  {store.depositAmount}
                  {' '}
                  ETH deposit.
                </span>
                <div
                  style={{
                    marginTop: 30,
                  }}
                  className={BTN_CLASS}
                  onClick={async () => {
                    if (isPayingDiff) return;
                    setIsPayingDiff(true);
                    try {
                      await store.auctionContract.estimateGas.winnerDepositExtra(WINNERS[store.web3Ctx.account.toLowerCase()], {
                        value: s,
                      });
                      setMessage(CONFIRM_TX);
                      const res = await store.auctionContract.winnerDepositExtra(WINNERS[store.web3Ctx.account.toLowerCase()], {
                        value: s,
                      });
                      setMessage(PROCESSING_TX);
                      await res.wait();
                      store.isUserWillReceiveAirdrop = true;
                      setMessage('Payment Success!');
                    } catch (error) {
                      let m = store.getErrorMessageWithHeader(error);
                      setMessage(`${m}`);
                    }
                    setIsPayingDiff(false);
                  }}
                >
                  {
                    isPayingDiff ? 'Loading...' : (
                      `Pay ${formatEther(s)} ETH`
                    )
                  }
                </div>
              </React.Fragment>
            );
          }
          return (
            <span>
              But oops, you didn't pay the difference. You are not on the airdrop list.
            </span>
          );
        })()}
        {
          message && (
            <div style={{color: message.startsWith('Error') && 'red'}}>
              {message}
            </div>
          )
        }
      </VFlex>
    </VFlex>
  );
});

export default WinnerPart;
