import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Dialog from './Dialog';
import { store } from '../../mobx/store';

interface VideoDialogProps {
}

const VideoDialog = observer((props:VideoDialogProps) => {
  return (
    <Dialog
      storeGetter={() => store.isShowVideoDialog}
    >
      <div
        className="w-lightbox-control w-lightbox-close"
        role="button"
        aria-label="close lightbox"
        tabIndex="0"
        style={{
          right: 50,
          top: 30,
          transform: 'scale(1.5)',
        }}
        onClick={() => {
          store.isShowVideoDialog = false;
        }}
      />
      {
        store.isShowVideoDialog && (
          <iframe
            className="embedly-embed"
            src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FO5TdnuUhIgs%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DO5TdnuUhIgs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FO5TdnuUhIgs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
            width="940"
            height="528"
            scrolling="no"
            title="YouTube embed"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen="true"
          />
        )
      }
    </Dialog>
  );
});

export default VideoDialog;
