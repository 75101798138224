import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { store } from '../mobx/store';

interface HeaderMenuProps {
}

const HeaderMenu = observer((props:HeaderMenuProps) => {
  return (
    <div
      className="nav-menu-wrapper"
    >
      <nav role="navigation" className="nav-menu w-nav-menu">
        <a href="#Home" className="nav_link">Home</a>
        <a href="#Auction" className="nav_link">Auction</a>
        <a href="#FAQs" className="nav_link">FAQ</a>
        <a href="#Team" className="nav_link">Team</a>
        <a href="#Partners" className="nav_link">Partners</a>
        <div className="social_links_wrapper navbar_sm">
          <a target="_blank" href="/#" className="social_link w-inline-block"><img width="13" sizes="(max-width: 767px) 100vw, (max-width: 991px) 13px, 1vw" src="images/faceb.png" srcSet="images/faceb-p-500.png 500w, images/faceb.png 766w" alt="" /></a>
          <a target="_blank" href="/#" className="social_link w-inline-block"><img width="13" sizes="(max-width: 767px) 100vw, (max-width: 991px) 13px, 1vw" src="images/2000px-Instagram_simple_icon.svg.png" srcSet="images/2000px-Instagram_simple_icon.svg-p-500.png 500w, images/2000px-Instagram_simple_icon.svg-p-800.png 800w, images/2000px-Instagram_simple_icon.svg-p-1080.png 1080w, images/2000px-Instagram_simple_icon.svg-p-1600.png 1600w, images/2000px-Instagram_simple_icon.svg.png 1970w" alt="" /></a>
          <a target="_blank" href="https://twitter.com/TaijiLabs" className="social_link w-inline-block" rel="noreferrer"><img width="13" sizes="(max-width: 767px) 100vw, (max-width: 991px) 13px, 1vw" src="images/twitter1.png" srcSet="images/twitter1-p-500.png 500w, images/twitter1-p-800.png 800w, images/twitter1.png 827w" alt="" /></a>
        </div>
        <a target="_blank" href="https://drive.google.com/file/d/1SeUlP1PvZ8dmwSofLAMQaT9OMMjY31Tp/view?usp=share_link" className="button navbar-button w-button" rel="noreferrer">⇓   Whitepaper</a>
      </nav>
    </div>
  );
});

export default HeaderMenu;
