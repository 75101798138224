import { createGlobalStyle } from 'styled-components';
import { COLORS, CONTAINER_MAX_WIDTH, FONT, MediaQueryMobileOnly, MediaQuerySMOnly, MediaQueryXSOnly } from './utils/constants';

const GlobalCSS = createGlobalStyle`
input {
  color: white;
}
`;

export default GlobalCSS;
